@import "../../global-styles/common.scss";

.modalWrapper {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
    backdrop-filter: blur(2px);

    .inside {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        z-index: 20;
        border: none;
        background-color: lighten($greyDark, 65%);
        border-radius: $radius;
        border: 1px solid lighten($greyDark, 45%);

        @media screen and (orientation: landscape) {
            padding: 30px;
        }
    }
}