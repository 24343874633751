@import "../../global-styles/common.scss";

.itemWrapper {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    border: 2px solid $blueNormal;
    border-radius: $radius;
    margin-bottom: 20px;

    .image {
        width: 100%;
        height: 20vh;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .content {
        padding: 20px;

        p {
            font-size: calc($sizeXXS/1.1);
        }

        .title {
            margin-top: 10px;
            margin-bottom: 20px;
            font-weight: 700;
        }

        .content1,
        .content2 {
            p {
                margin-top: 20px;
            }
        }


        .signature {
            margin-top: 20px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;

            p {
                color: $blueNormal;
                font-weight: 700;
            }
        }

    }

    .buttons {
        width: 90%;
        margin: 10px auto 20px auto;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        button {
            @include standardButton;
        }
    }

    @media screen and (min-width:map-get($breakpoints, large)) {

        .image {

            height: 30vw;

        }

        .content {
            padding: 20px;

            p {
                font-size: calc($sizeXS);
            }
        }
    }

    @media screen and (min-width:map-get($breakpoints, xlarge)) {

        .image {

            height: 20vw;

        }

        .content {
            padding: 20px;

            p {
                font-size: calc($sizeXS);
            }
        }
    }
}