@import "../../global-styles/common.scss";

.wrapper {
    @include wrapper;

    .inside {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
            text-align: center;
            margin-bottom: 30px;
        }

        .buttons {
            width: 100%;
            margin-bottom: 30px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;

            button {
                @include standardButton;
            }

            button:nth-child(1) {
                @include backButton;
            }
        }
    }

    @media screen and (min-width:map-get($breakpoints, large)) {
        .inside {
            h3 {
                font-size: $sizeXXL;
            }
        }
    }
}