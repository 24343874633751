@import "../../global-styles/common.scss";

.inside {
    background-color: $blueNormal;
    border-radius: $radius;

    p {
        padding: 20px;
        color: $greyLight
    }
}